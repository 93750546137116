<template>
  <div>
    <div class="md:flex flex-row-reverse space-x-4 bg-gradient-to-r from-red-900 via-yellow-600 to-blue-900">
      <svg id="Слой_1" style="enable-background:new 0 0 5000 3500;" version="1.1" viewBox="0 0 5000 3500"
           x="0px" xml:space="preserve"
           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
        <g id="LAMP">
	<polygon class="st0" points="3637.1,3171 1092.1,3171 2132.1,701.6 2597.1,701.6 	"/>
          <polyline class="st1" points="2320.5,515.8 2364.6,471.7 2408.7,515.8 	"/>
          <line class="st1" x1="2364.6" x2="2364.6" y1="471.7" y2="324"/>
          <circle class="st2" cx="2364.6" cy="707.6" r="71"/>
          <path class="st3" d="M2597.1,701.6h-464.9l6.2-28.3c20.4-93.2,115.5-160.8,226.3-160.8c55.4,0,106.9,16.9,147,45.4
		c40.2,28.5,69,68.7,79.2,115.3L2597.1,701.6z"/>
</g>
        <g id="BOOKSHELF">
	<g>
		<rect class="st4" height="298" width="47.2" x="3033.9" y="2294.2"/>
    <path class="st5" d="M3938.2,2592.2h-268.7c-9.7,0-17.2-8.4-16-18l37.4-315.7c1-8.1,7.9-14.2,16-14.2h268.7c9.7,0,17.2,8.4,16,18
			l-37.4,315.7C3953.2,2586.1,3946.3,2592.2,3938.2,2592.2z"/>
    <path class="st6" d="M3791.8,2592.2h-212.2c-13,0-23.1-11.4-21.6-24.3l27.2-229.9c1.3-10.9,10.6-19.2,21.6-19.2h217.3
			L3791.8,2592.2z"/>
    <path class="st6" d="M3815.6,2592.2h-202.5c-13,0-23.1-11.4-21.6-24.3l27.2-229.9c1.3-10.9,10.6-19.2,21.6-19.2h205.1
			c6.5,0,11.6,5.7,10.8,12.2l-29.8,251.5C3825.8,2588.1,3821.1,2592.2,3815.6,2592.2z"/>
    <rect class="st3" height="253.2" width="81.9" x="3084.1" y="2339"/>
    <rect class="st3" height="253.2" width="115.3" x="3137.5" y="2339"/>
    <polygon class="st3" points="3392.5,2592.2 3360.6,2592.2 3241.9,2311.2 3273.8,2311.2 		"/>
    <polygon class="st3" points="3453.3,2592.2 3393.9,2592.2 3291.7,2353.9 3351.1,2353.9 		"/>
    <rect class="st3" height="253.2" width="82.6" x="2948.3" y="2339"/>
	</g>
          <g>
		<g>
			<rect class="st3" height="49.4" width="384.2" x="2948.3" y="2144.9"/>
      <rect class="st7" height="39.8" width="486.7" x="2948.3" y="2107.1"/>
      <rect class="st8" height="56.4" width="328.5" x="2948.3" y="2049.7"/>
      <rect class="st3" height="42.1" width="384.2" x="2948.3" y="2006.6"/>
		</g>
            <g>
			<path class="st6" d="M3995.4,1895.6l-0.3,1.7l-45.4,297h-421.2l45.4-297l0.6-4.2c7.2-14.6,51.2-25.9,104.5-25.9
				c58.3,0,105.5,13.5,105.5,30.1c0-16.6,47.2-30.1,105.5-30.1C3946.3,1867.3,3992.2,1879.8,3995.4,1895.6z"/>
              <line class="st1" x1="3739.6" x2="3784.6" y1="2194.4" y2="1897.4"/>
              <path class="st8" d="M3739.6,2194.4c0-16.6-47.2-30.1-105.5-30.1s-105.5,13.5-105.5,30.1H3739.6z"/>
              <path class="st8" d="M3950.6,2194.4c0-16.6-47.2-30.1-105.5-30.1s-105.5,13.5-105.5,30.1H3950.6z"/>
              <path class="st9" d="M3741.2,2188.1c-0.6-16.6,46.1-31.7,104.4-33.8c36.4-1.3,72.3,3.8,91.2,11.1l45-282.9"/>
		</g>
	</g>
          <g>
		<path class="st10" d="M3961.8,1796.5h-378.2v-84.2c0-8.3,6.7-15,15-15h348.3c8.3,0,15,6.7,15,15V1796.5z"/>
            <path class="st8" d="M3992,1693.3h-325.9c-5.1,0-9.1-4.1-9.1-9.1v-33.5c0-5.1,4.1-9.1,9.1-9.1H3992c5.1,0,9.1,4.1,9.1,9.1v33.5
			C4001.1,1689.2,3997,1693.3,3992,1693.3z"/>
            <rect class="st7" height="31.4" width="386.6" x="3618.1" y="1610.2"/>
            <path class="st10" d="M3997.7,1610.2h-295.1c-3.9,0-7-3.2-7-7v-25.3c0-3.9,3.2-7,7-7h295.1c3.9,0,7,3.2,7,7v25.3
			C4004.7,1607,4001.6,1610.2,3997.7,1610.2z"/>
            <rect class="st3" height="308.9" width="113.5" x="3072.4" y="1487.6"/>
            <rect class="st10" height="308.9" width="45.8" x="3026.6" y="1487.6"/>
            <rect class="st10" height="308.9" width="77" x="2949.6" y="1487.6"/>
            <rect class="st3" height="253.2" width="99.5" x="3200.9" y="1543.4"/>
            <path class="st10" d="M3208.1,1796.5h-39.2c-3.8,0-7-3.1-7-7v-239.3c0-3.8,3.1-7,7-7h39.2c3.8,0,7,3.1,7,7v239.3
			C3215,1793.4,3211.9,1796.5,3208.1,1796.5z"/>
            <polygon class="st5" points="3300.4,1543.4 3300.4,1682.2 3246.5,1543.4 		"/>
            <path class="st6" d="M3530.8,1796.5h-159.2c-3.5,0-6.7-2.2-7.9-5.4l-103.4-266.2c-2.2-5.6,1.9-11.6,7.9-11.6h159.2
			c3.5,0,6.7,2.2,7.9,5.4l103.4,266.2C3540.9,1790.5,3536.8,1796.5,3530.8,1796.5z"/>
            <path class="st6" d="M3556.8,1796.5h-159.2c-3.5,0-6.7-2.2-7.9-5.4l-103.4-266.2c-2.2-5.6,1.9-11.6,7.9-11.6h159.2
			c3.5,0,6.7,2.2,7.9,5.4l103.4,266.2C3566.9,1790.5,3562.8,1796.5,3556.8,1796.5z"/>
	</g>
          <g>
		<path class="st1" d="M3836.4,1331.1c-14.9,4.2-30.7,6.5-46.9,6.5c-94.7,0-171.4-76.7-171.4-171.4c0-78.1,52.3-144.1,123.8-164.7
			L3836.4,1331.1z"/>
            <line class="st1" x1="3789.5" x2="3789.5" y1="1337.6" y2="1398.7"/>
            <circle class="st11" cx="3789.5" cy="1166.2" r="145.2"/>
            <polygon class="st6" points="3850.2,1398.3 3729.3,1398.3 3789.3,1368.2 		"/>
	</g>
          <path class="st3"
                d="M4004.7,1398.7V3171H3950v-129c0-28.7-23.3-52-52-52h-843.6c-28.7,0-52,23.3-52,52v129h-54.1V1398.7H4004.7z"
          />
          <path class="st12" d="M3928.8,2940.9h-906.6c-11.5,0-20.9-9.3-20.9-20.9v-260.9c0-11.5,9.3-20.9,20.9-20.9h906.6
		c11.5,0,20.9,9.3,20.9,20.9v260.9C3949.7,2931.6,3940.3,2940.9,3928.8,2940.9z"/>
          <g>
		<polygon class="st5" points="3523.3,1155.3 3523.3,1398.7 3438.1,1398.7 		"/>
            <path class="st6" d="M3523.3,1155.3l-0.6,1.8l-84.1,241.6h-391l84.1-241.6l2.7-7.7l0,0c12.6-18.1,47.5-31.3,89.1-32
			c0.7,0,1.3,0,2,0c0.7,0,1.3,0,2,0c48.6,0,91.9,16.9,99.8,39.1c7.8-22.2,49.2-39.1,97.8-39.1
			C3472.7,1117.4,3514.4,1133.7,3523.3,1155.3z"/>
            <line class="st9" x1="3327.2" x2="3242.3" y1="1156.5" y2="1393.7"/>
            <path class="st8"
                  d="M3242.1,1395.7h-194.5c7.2-22.5,47.6-39.8,96.2-39.8C3192.6,1355.9,3234.9,1373.2,3242.1,1395.7z"/>
            <path class="st8"
                  d="M3438.1,1395.7h-194.5c7.2-22.5,47.6-39.8,96.2-39.8C3388.6,1355.9,3430.9,1373.2,3438.1,1395.7z"/>
	</g>
          <path class="st3" d="M3410.4,2715.5c0-7.8,6.3-14.2,14.2-14.2h106.1c7.8,0,14.2,6.3,14.2,14.2"/>
          <line class="st3" x1="2948.3" x2="4004.7" y1="2194.4" y2="2194.4"/>
          <line class="st3" x1="2948.3" x2="4004.7" y1="1796.5" y2="1796.5"/>
          <line class="st3" x1="2948.3" x2="4004.7" y1="2592.2" y2="2592.2"/>
          <polyline class="st13" points="3078.3,1027.1 2948.3,1398.7 4004.7,1398.7 4134.7,1027.1 	"/>
</g>
        <g id="PLANT">
	<g>
		<path class="st7" d="M4253.3,3075.6c0,20.1-3.7,39.4-10.5,57.3c-9.9,26-35,43-62.9,43h-211.7c-27.8,0-53-17-62.8-43
			c-6.8-17.9-10.5-37.2-10.5-57.3c0-58,30.7-109.2,77.6-139.8V2902c0,14.8,45.5,26.8,101.5,26.8c56.1,0,101.5-12,101.5-26.8v33.9
			C4222.6,2966.4,4253.3,3017.6,4253.3,3075.6z"/>
    <g>
			<path class="st14" d="M4175.7,2926.6v9c-16.1,12.8-59.9,19.6-101.5,19.6c-41.6,0-85.4-6.8-101.5-19.6v-9c1.9,4.3,10,9.9,28,14.6
				c19.6,5.2,45.7,8,73.5,8c27.8,0,53.9-2.8,73.5-8C4165.7,2936.4,4173.7,2930.8,4175.7,2926.6z"/>
		</g>
    <path class="st4" d="M4175.7,2902c0,14.8-45.5,26.8-101.5,26.8s-101.5-12-101.5-26.8c0-1.9,0.7-3.7,2.1-5.5
			c9.6-12.1,50.4-21.3,99.4-21.3s89.8,9.1,99.4,21.3C4174.9,2898.3,4175.7,2900.1,4175.7,2902z"/>
    <g>
			<circle class="st1" cx="3938.3" cy="3020.3" r="12.8"/>
      <circle class="st1" cx="3981.7" cy="3051.8" r="12.8"/>
      <circle class="st1" cx="4035.7" cy="3014.4" r="12.8"/>
      <circle class="st1" cx="4028.1" cy="3099.4" r="12.8"/>
      <circle class="st1" cx="3980.1" cy="3136.7" r="12.8"/>
      <circle class="st1" cx="3931.7" cy="3099.4" r="12.8"/>
      <circle class="st1" cx="3988.7" cy="2972.8" r="12.8"/>
      <circle class="st1" cx="4090.6" cy="2982.5" r="12.8"/>
      <circle class="st1" cx="4079.9" cy="3060.1" r="12.8"/>
      <circle class="st1" cx="4116.9" cy="3111.1" r="12.8"/>
      <circle class="st1" cx="4068.9" cy="3149.5" r="12.8"/>
      <circle class="st1" cx="4130.6" cy="3029.5" r="12.8"/>
      <circle class="st1" cx="4167.7" cy="2977" r="12.8"/>
      <circle class="st1" cx="4209.9" cy="3019.5" r="12.8"/>
      <circle class="st1" cx="4161.6" cy="3061.4" r="12.8"/>
      <circle class="st1" cx="4219.4" cy="3088.2" r="12.8"/>
      <circle class="st1" cx="4180.5" cy="3137.4" r="12.8"/>
		</g>
	</g>
          <g>
		<path class="st6" d="M4298.4,2746.2c0,0,95-51.3,110.7,38.3c18.2,103.5-105.4,194.6-105.4,194.6s-26.6-65.9-97.9-122.7
			C4144.1,2807.3,4195.3,2677.1,4298.4,2746.2z"/>
            <path class="st8" d="M4111.9,2632.1c45.1,0.4,109.9-91.2,48.7-191.7c-73.9-121.4-86.6-191.7-86.6-191.7s-108.7,143.8-90.2,254.1
			C4004.1,2623.6,4111.9,2632.1,4111.9,2632.1z"/>
            <path class="st6" d="M4119.7,2571.1c65.9,17.4,128.5-22.7,129.8-114.1c2.3-161.6,32.9-214.2,32.9-214.2s-205.3,73.9-236.4,188.7
			C4016.4,2540.9,4119.7,2571.1,4119.7,2571.1z"/>
            <path class="st8" d="M3844.3,2677.5c-2.4,0.2-4.9,0.4-7.6,0.6c-141.8,10.6-182.6,140.4-182.6,140.4s-8.7-9.4-6.9-41.9
			c3.4-83.8,80.5-170.4,168.5-115.4C3825.5,2667.4,3835.1,2672.7,3844.3,2677.5z"/>
            <path class="st6" d="M4005.1,2709.4c-25,1.6-82.1,8.5-160.8-31.9c-9.2-4.7-18.8-10.1-28.6-16.3c-88-55-163.1,31.6-166.5,115.4
			c-0.2,4.1,0.8,8.2,1,12.2c-8.3-72.8-8.1-254,212.2-247.9C4013.8,2545.3,4005.1,2709.4,4005.1,2709.4z"/>
            <path class="st1" d="M4041.1,2896.7c0,0,26.1-363.7-326.6-316.2"/>
            <path class="st1" d="M4282.4,2242.8c-222.2,227-180.8,653.9-180.8,653.9c-23.4-163.3,120.2-325.6,196.7-154.8
			c22.8,50.8,5.3,237.1,5.3,237.1"/>
	</g>
</g>
        <g id="CHAIR__x26__TABLE">
	<g id="CHAIR">
		<path class="st8" d="M990,2610.8l-29.8,26.1c-4.8-5.7-9.1-12-12.8-18.9l-185.8-346.2c-7.8-14.5,2.7-32,19.1-32h26
			c20.8,0,39.8,11.4,49.6,29.7l146.1,272.2C1015,2564.9,1009.8,2593.6,990,2610.8z"/>
    <path class="st8" d="M1495.8,2618.4L1495.8,2618.4l-432.3,88.8v-9c0-43.1,30.3-80.2,72.5-88.9l278.5-57.2
			C1456.5,2543.5,1495.8,2575.6,1495.8,2618.4z"/>
    <path class="st3"
          d="M1446.8,3171v-433.1c0-35.9-32.9-62.7-68.1-55.5l-271.5,55.7c-32.3,6.6-58.9,29.2-70.7,60L893.2,3171"/>
    <path class="st3" d="M760.5,2269.9l211.1,393.3c18.1,33.7,56.1,51.5,93.6,43.8l431.6-88.6"/>
	</g>
          <g id="BOOKS">
		<rect class="st4" height="48.8" width="365.3" x="1283.8" y="2297.2"/>
            <g>
			<path class="st7" d="M1582.5,2244.2c4.4,4.1,7.1,11.5,7.1,17.7c0,12.4-10.8,25.1-24.2,25.1h-333.2l0,0c0,5.8,4.7,10.5,10.5,10.5
				h402.1c10.8,0,20.5-4,27.6-10.6c7.1-6.5,11.4-15.6,11.4-25.6c0-20-17.5-36.1-39-36.1h-402.1c-5.8,0-10.5,4.7-10.5,10.5v1.9h333.2
				C1572.1,2237.7,1578.1,2240.2,1582.5,2244.2z"/>
              <path class="st7" d="M1256.3,2237.7v48.4h-24v4h334.3c3.7,0,7.3-0.7,10.5-2c0,0,0,0,0,0c3.6-1.4,6.8-3.6,9.4-6.2
				c5.1-5.1,8.3-12.2,8.3-20c0-15.6-12.7-28.2-28.2-28.2h-334.3v4H1256.3z"/>
              <path class="st14" d="M1247,2237.7v48.4h318.4c13.4,0,24.2-10.8,24.2-24.2c0-6.7-2.7-12.7-7.1-17.1c-4.4-4.4-10.4-7.1-17.1-7.1
				H1247z"/>
              <line class="st15" x1="1247" x2="1247" y1="2237.7" y2="2286.1"/>
              <path class="st8" d="M1520.9,2272v40.4h27.9v-35.2c0-8.4,6.8-15.2,15.2-15.2h12.4v-5.2h-40.4
				C1527.8,2256.8,1520.9,2263.6,1520.9,2272z"/>
		</g>
	</g>
          <g id="TABLE">
		<path class="st3" d="M1078.7,2387.8c0-21.3,17.3-38.6,38.6-38.6h759c21.3,0,38.6,17.3,38.6,38.6"/>
            <path class="st3"
                  d="M1915.5,3171l-214.3-746.4c-9.5-33-42.2-56-79.6-56h-249.7c-37.4,0-70.1,23-79.6,56L1078.1,3171"/>
	</g>
</g>
        <g id="CHARACTER">
	<g id="SHOES">
		<g>
			<path class="st16" d="M2095.4,3041.8v108.8c0,11.3,9.1,20.4,20.4,20.4h272c0,0,1.6-50.5-39.8-64.6
				c-70.3-23.9-103.1-64.6-103.1-64.6H2095.4z"/>
      <path class="st17" d="M2246.7,3097.5c11.1-3.1,51.1-25.2,37.4-38.9c-6.5-6.5-22.1,0-22.1,0"/>
      <path class="st17" d="M2287.7,3112.5c11.1-3.1,49.5-27.8,34.4-39.9c-11.4-9.2-28.5,8.6-28.5,8.6"/>
      <path class="st7" d="M2253.3,3050.6l-157.9,49.1v-57.9H2245C2245,3041.8,2247.7,3045.2,2253.3,3050.6z"/>
		</g>
    <g>
			<path class="st16" d="M2436.4,3041.8v108.8c0,11.3,9.1,20.4,20.4,20.4h272c0,0,1.6-50.5-39.8-64.6
				c-70.3-23.9-103.1-64.6-103.1-64.6H2436.4z"/>
      <path class="st17" d="M2587.7,3097.5c11.1-3.1,51.1-25.2,37.4-38.9c-6.5-6.5-22.1,0-22.1,0"/>
      <path class="st17" d="M2628.7,3112.5c11.1-3.1,49.5-27.8,34.4-39.9c-11.4-9.2-28.5,8.6-28.5,8.6"/>
      <path class="st7" d="M2594.3,3050.6l-157.9,49.1v-57.9H2586C2586,3041.8,2588.7,3045.2,2594.3,3050.6z"/>
		</g>
	</g>
          <g id="LEGS">
		<polygon class="st18" points="2168.9,1958.8 2070.4,2990 2276.4,2990 2379.9,2490.3 2422.2,2596.8 2422.2,2990 2636.7,2990
			2636.7,2549.1 2522.3,1958.8 		"/>
            <line class="st19" x1="2276.2" x2="2180.4" y1="2206.5" y2="2990"/>
            <polyline class="st19" points="2525.7,2990 2525.7,2549.1 2391.3,2008.8 		"/>
            <path class="st20" d="M2419.2,2596.8l-128.4-372.9c-4.4-12.8-16.5-21.4-30.1-21.4"/>
            <polygon class="st5" points="2152.6,2129.3 2168.9,1958.8 2522.3,1958.8 2532.4,2011.2 		"/>
            <path class="st5" d="M2637.1,3041.8h-215.7c-8.1,0-14.7-6.6-14.7-14.7v-22.4c0-8.1,6.6-14.7,14.7-14.7h215.7
			c8.1,0,14.7,6.6,14.7,14.7v22.4C2651.7,3035.2,2645.2,3041.8,2637.1,3041.8z"/>
            <path class="st5" d="M2280.1,3041.8h-215.7c-8.1,0-14.7-6.6-14.7-14.7v-22.4c0-8.1,6.6-14.7,14.7-14.7h215.7
			c8.1,0,14.7,6.6,14.7,14.7v22.4C2294.7,3035.2,2288.2,3041.8,2280.1,3041.8z"/>
	</g>
          <g id="BOOK">
		<path class="st4" d="M2855.8,1989l67.6,252.9l-337,90.1l-67.7-253.1c-2.1-7.9-7.2-14.1-13.7-17.9c-6.5-3.8-14.5-5.1-22.4-3
			c-15.7,4.2-25.1,20.4-20.9,36.1l67.7,253.1l-7.4,2l-67.6-252.9c-5.4-20.1,6.2-40.7,26-46.7c0.4-0.1,0.8-0.2,1.2-0.3l327.2-87.5
			C2829.3,1956.4,2850.3,1968.6,2855.8,1989z"/>
            <path class="st5" d="M2526.2,2077.2l67.6,252.9l-7.4,2l-67.7-253.1c-2.1-7.9-7.2-14.1-13.7-17.9c-6.5-3.8-14.5-5.1-22.4-3
			c-15.7,4.2-25.1,20.4-20.9,36.1l67.7,253.1l-7.4,2l-67.6-252.9c-5.4-20.1,6.2-40.7,26-46.7
			C2500.5,2045,2520.8,2057.1,2526.2,2077.2z"/>
            <path class="st14" d="M2518.7,2078.9l63.5,237.6l-57,15.2l-63.5-237.6c-4.2-15.7,5.1-31.9,20.9-36.1c7.9-2.1,15.9-0.8,22.4,3
			C2511.5,2064.8,2516.6,2071,2518.7,2078.9z"/>
	</g>
          <g id="SHIRT">
		<path class="st2" d="M2856.8,2123l-153.3,52.8l-121-217h-469c-79.7,0-139.3-73.3-123-151.3l48.8-234.4c15.3-73.4,80-126,154.9-126
			h320.7c77.7,0,143.9,56.4,156.2,133.1l48.3,228.2L2856.8,2123z"/>
            <path class="st14" d="M2088.5,1956.3c-55.2-11.1-96.2-58.1-100.4-113.5c4.2-2.9,8.9-5.4,13.9-7.2c36.3-13.4,77.8,8.6,92.9,49.1
			C2104.2,1910.1,2101,1936.7,2088.5,1956.3z"/>
	</g>
          <g id="HEAD">
		<polygon id="NECK" class="st21" points="2437.7,1306.5 2437.7,1447.2 2282.4,1447.2 2318.2,1353.6 		"/>
            <path id="HAIR" class="st5" d="M2473.4,1247.3c-0.1,0-0.3,0.1-0.4,0.1v0h0l-46.1,12.2c-16.5,4.4-29.2,16.6-34.6,32h-17.7
			c-11.2,0-20.3,9.1-20.3,20.3v0.3c0,10.6,8.1,19.3,18.5,20.2c-0.1,0.1-0.1,0.1-0.1,0.1s-8.2,39-56.1,25.9c0,0,1.9-102.6,3.9-134.5
			c1.7-26.3,141.2-76.1,154.1-54.9c11.4,18.8-1.8,27.6-13,31.5c19.8-4.2,57.2-9.8,61,6.9C2527,1226.8,2487.5,1243.6,2473.4,1247.3z"
            />
            <g id="HEAD_1_">
			<path class="st21" d="M2482.4,1319.2h-4.9l1.4,24c1,17.2-12.8,31.8-30,31.8h-21.6c-20.8,0-37.7-16.9-37.7-37.7v-4.7h-14.9
				c-11.2,0-20.3-9.1-20.3-20.3v-0.3c0-11.2,9.1-20.3,20.3-20.3h17.7c5.4-15.4,18.1-27.7,34.6-32l46.1-12.2h0l15.8,63.3
				C2490.5,1314.8,2486.7,1319.2,2482.4,1319.2z"/>
              <g>
				<path class="st22" d="M2394.3,1339.2c-2.1,22,19.9,37.7,40.7,37.7"/>
                <path class="st22" d="M2386.2,1315.7c0-7.8-6.3-14.1-14.1-14.1"/>
                <ellipse class="st5" cx="2465.4" cy="1293.7" rx="3.7" ry="7.7"/>
                <path class="st22" d="M2449.4,1277.5c0,0,12.3-9.4,29.9-9.4"/>
			</g>
		</g>
	</g>
          <g id="BACKPACK">
		<path class="st16" d="M2401.8,2022.9c0,0,122.5,5.3,165.6-93l-22.8-41.7l-68.1,26.2L2401.8,2022.9z"/>
            <path class="st23" d="M2499.9,1421.4c-5.9,0.5-11.3,3.1-15.3,7.1c-4.4,4.4-7.2,10.5-7.2,17.3v492.7c0,47-38.1,85.1-85.1,85.1
			h-188.9c-50.8,0-90-44.1-84.6-94.2l0-0.1l68-467.7c3.4-23.1,23.1-40.2,46.5-40.2H2499.9z"/>
            <path class="st16" d="M2480.9,1421.4c-5.9,0.5-11.3,3.1-15.3,7.1c-3.7,3.7-6.2,8.5-6.9,13.9c-0.1,1.1-0.2,2.2-0.2,3.3l-4,37.7
			l-48,455c0,47-38.1,85.1-85.1,85.1h-117.9c-50.8,0-90-44.1-84.6-94.2l0-0.1l30.7-211.4l37.3-256.3c0.3-2.2,0.8-4.4,1.4-6.6
			c5.8-19.7,24-33.6,45-33.6H2480.9z"/>
            <path class="st8"
                  d="M2438.8,1519.5l-17.9,134.3c-5.9,27.5-27.3,42.2-58.3,47.2l-212,30.8l30.9-212.4H2438.8z"/>
            <path class="st23" d="M2473.3,1421.4l-5.3,0c-9.6-0.1-18,6.7-19.9,16.2l-31.2,201.3c-5.9,27.5-30.2,47.2-58.3,47.2h-195.1
			c-23.6,0-41.2-21.8-36.1-44.9l37.6-170.8c6.3-28.6,31.7-49,61-49H2473.3z"/>
            <path class="st16" d="M2312.5,1742.1l49.7-303.9c1-6.1,6.3-10.7,12.5-10.7l0,0c7.8,0,13.8,7,12.5,14.8l-49.7,303.9
			c-1,6.1-6.3,10.7-12.5,10.7l0,0C2317.2,1756.9,2311.2,1749.9,2312.5,1742.1z"/>
            <path class="st16" d="M2201.4,1742.1l49.7-303.9c1-6.1,6.3-10.7,12.5-10.7l0,0c7.8,0,13.8,7,12.5,14.8l-49.7,303.9
			c-1,6.1-6.3,10.7-12.5,10.7l0,0C2206.1,1756.9,2200.1,1749.9,2201.4,1742.1z"/>
            <path class="st16" d="M2526.4,1447.3l-48.9,0.2v-1.7c0-6.8,2.7-12.9,7.2-17.3c4-4,9.3-6.6,15.3-7.1h4
			C2516.3,1422.4,2526,1432.8,2526.4,1447.3z"/>
	</g>
          <g id="HAND">
		<g>
			<path class="st21" d="M2838.5,2129.3l41.2,106.3c6.6,17.1-3,36.1-20.6,41l-90.5,24.8c-5.5,1.5-11.3,0.2-15.6-3.5l-9.2-8l18.8-5
				c8.3-2.2,13.9-9.9,13.5-18.5l-2.1-21.2c-0.4-9.5-8.1-17.1-17.6-17.4h1c-11.5-0.4-22.7-7.5-27.2-18.1l-14.4-38.3L2838.5,2129.3z"
      />
      <path class="st24" d="M2745.7,2292l14.8-4c8.3-2.2,13.9-8.9,13.5-17.5l-1.1-23.2c-0.4-9.5-8.1-17.1-17.6-17.4l0,0
				c-11.5-0.4-21.7-7.5-26.2-18.1l-15.4-36.3"/>
      <path class="st24" d="M2794.1,2291.8c10.6-2.8,17.8-12.6,17.3-23.6l-1.4-29.6"/>
      <path class="st24" d="M2832.4,2281.9c10.6-2.8,17.8-12.6,17.3-23.6l-1.4-29.6"/>
		</g>
            <path class="st20" d="M2489.8,1686.1l24.2,126.6c4.1,21.6,11.7,42.5,22.4,61.7l167,301.4l153.3-52.8"/>
            <path class="st14" d="M2591.1,1973.2l-54.7-98.8c-9.2-16.7-16.1-34.5-20.5-53c5.2-1.7,10.6-2.9,16.3-3.4
			c43.2-3.8,81.5,32.5,85.6,81C2620.2,1928.4,2609.5,1955.5,2591.1,1973.2z"/>
	</g>
</g>
</svg>

      <div class="py-20 px-5 text-white">
        <h2 class="text-4xl my-2">Disciplines covered by our essay writing service</h2>
        <p class="my-2 py-2 text-lg leading-relaxed">
          Our custom academic writing service creates papers in 80+ disciplines to help students manage their studies.
          These are the most popular disciplines our professionals have covered recently.
        </p>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="discipline in popularDisciplines" :key="discipline.id">
            <p class="text-base flex space-x-2 items-center">
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"/>
              </svg>
              <span>{{ discipline.name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DISCIPLINES} from "@/components/order/orderObjects";
import Navigation from "@/components/layout/Navigation";
import Footer from "@/components/layout/Footer";

export default {
  name: "WritingServices",
  components: {Footer, Navigation},
  data() {
    return {
      DISCIPLINES,
      popularDisciplines: [],
    }
  },
  methods: {
    getPopularDisciplines() {
      for (let i = 1; i < 29; i++) {
        this.popularDisciplines.push(DISCIPLINES[i])
      }
    }
  },
  mounted() {
    document.title = 'Writing services | BestDissertations.org'
    this.getPopularDisciplines()
  }
}
</script>

<style scoped>
.st0 {
  fill: #D0F0E4;
}

.st1 {
  fill: none;
  stroke: #FFCF2D;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st2 {
  fill: #FFCF2D;
  stroke: #FFFFFF;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: #66B0F0;
}

.st5 {
  fill: #9A3B82;
}

.st6 {
  fill: #FFFFFF;
  stroke: #FFCF2D;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st7 {
  fill: #FE5244;
}

.st8 {
  fill: #FFCF2D;
}

.st9 {
  fill: none;
  stroke: #FFCF2D;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st10 {
  fill: #FFFFFF;
  stroke: #9A3B82;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st11 {
  fill: #FFFFFF;
  stroke: #3FB1B5;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st12 {
  fill: none;
  stroke: #3FB1B5;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st13 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st14 {
  fill: #FFFFFF;
}

.st15 {
  fill: none;
  stroke: #FE5244;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st16 {
  fill: #FFFFFF;
  stroke: #FE5244;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st17 {
  fill: none;
  stroke: #FE5244;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st18 {
  fill: #3FB1B5;
}

.st19 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st20 {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st21 {
  fill: #E57281;
}

.st22 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st23 {
  fill: #FE5244;
  stroke: #FE5244;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st24 {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
